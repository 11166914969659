import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Loader from '../components/Loader';
import { getPodcasts, getWaveform, listInventory } from "../api/api";
import { useHistory, useLocation } from "react-router-dom";

export default (props) => {

  const [podcasts, setPodcasts] = useState([]);
  const [inventory, setInventory] = useState([]);

  let params = new URLSearchParams(useLocation().search);

  let data;
  let mediafile = params.get("mediafile");
  let title = params.get("title");
  let referrer = params.get("referrer");
  let podcastId = params.get("podcast_id") || params.get("podcastid");
  let adbreaks = params.get("adbreaks");
  let loading = true;
  let message = "Preparing your data...";
  const history = useHistory();

  useEffect(async () => {
    data = await doGetWaveform();

    if(adbreaks&& adbreaks.length) {
      data = await convertAdbreaks(adbreaks);
    }

    await new Promise(resolve => setTimeout(resolve, 5000));

    if(data.podcast && data.id) {
      history.push(`/podcasts/${data.podcast}/episodes/${data.id}`);
    } else {
      loading = false;
      console.log(data);
      message = "an error occured (see console log for detailed error message)";
    }

  }, []);

  let convertAdbreaks = (breaks) => {

    let matches = ["preroll", "midroll", "postroll"];
    let pre = breaks.filter((a) => {
      return a.type.toLowerCase().includes("preroll");
    });
    let mid = breaks.filter((a) => {
      return a.type.toLowerCase().includes("midroll");
    });
    let post = breaks.filter((a) => {
      return a.type.toLowerCase().includes("postroll");
    });

    pre.forEach((a) => { a.title = "Preroll" });
    mid.forEach((a) => { a.title = "2. Midroll" });
    post.forEach((a) => { a.title = "Postroll" });

    mid[0].title = "1. Midroll vorne";
    if(mid.length > 1) {
      mid[mid.length - 1].title = "3. Midroll hinten";
    }

    breaks = [...pre, ...mid, ...post];

    breaks.forEach((a) => {

      a.name = a.title;
      a.time = a.position_in_ms / 1000;

      delete a.title;
      delete a.position_in_ms;

      a.type = "point";

      data.adcalls.forEach((b, index) => {
        if(a.name === b.name) {
          a.adcall = b.id;
        }
      });
    });

    return getWaveform({
      "mediafile_url": mediafile,
      "podigee_podcast_id": podcastId,
      "title": title,
      "markers_json": JSON.stringify(breaks),
    }).then(response => response.json());
  }

  let doGetWaveform = () => {

    try {
      adbreaks = JSON.parse(adbreaks);
    } catch(e) {}

    if(podcastId === "6929" || podcastId === "bleibentspannt") {
      mediafile = mediafile.replace("//main.podigee-cdn.net", "//bleib-entspannt.cdn.svmaudio.com");
      mediafile = mediafile.replace("//audio.podigee-cdn.net", "//bleib-entspannt.cdn.svmaudio.com");
      mediafile = mediafile.replace("//cdn.podigee-cdn.net", "//bleib-entspannt.cdn.svmaudio.com");
    }
    if(podcastId === "3274" || podcastId === "dieser-eine-moment") {
      mediafile = mediafile.replace("//main.podigee-cdn.net", "//dieser-eine-moment.cdn.svmaudio.com");
      mediafile = mediafile.replace("//audio.podigee-cdn.net", "//dieser-eine-moment.cdn.svmaudio.com");
      mediafile = mediafile.replace("//cdn.podigee-cdn.net", "//dieser-eine-moment.cdn.svmaudio.com");
    }
    if(podcastId === "44427") {
      mediafile = mediafile.replace("//main.podigee-cdn.net", "//dersechzehner.cdn.svmaudio.com");
      mediafile = mediafile.replace("//audio.podigee-cdn.net", "//dersechzehner.cdn.svmaudio.com");
      mediafile = mediafile.replace("//cdn.podigee-cdn.net", "//dersechzehner.cdn.svmaudio.com");
    } else {
      mediafile = mediafile.replace("//main.podigee-cdn.net", "//mpc.cdn.svmaudio.com");
      mediafile = mediafile.replace("//audio.podigee-cdn.net", "//apc.cdn.svmaudio.com");
      mediafile = mediafile.replace("//cdn.podigee-cdn.net", "//cpc.cdn.svmaudio.com");
    }

    mediafile = mediafile.replace("//traffic.libsyn.com/forcedn/", "//tlc.cdn.svmaudio.com/secure/");
    mediafile = mediafile.replace("//traffic.libsyn.com/forcedn/", "//tlc.cdn.svmaudio.com/secure/");
    mediafile = mediafile.replace("//traffic.libsyn.com/forcedn/", "//tlc.cdn.svmaudio.com/secure/");

    let id = podcastId || referrer.split("/")[2];

    return getWaveform({
      "mediafile_url": mediafile,
      "podigee_podcast_id": id,
      "title": title
    }).then(response => response.json());
  }

  return (
    <Entry>
      <LoaderWrapper>
        <span>Preparing your data...</span>
        <Loader loading={loading} />
      </LoaderWrapper>
    </Entry>
  );
};

const Entry = styled.div``;
const Title = styled.div``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SaveButton = styled.div`
  display: flex;
  align-items: center;
`;
const LoaderWrapper = styled.div`
  //display: inline-block;
  //padding: 12px;
  display: flex;
  align-items: center;
  justify-content: left;
  
  .loader {
    margin: 0 15px;
    font-size: 2.5px;
  }
`;
